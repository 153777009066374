import { FC, ReactNode, useState } from 'react';
import { Form } from 'antd';
import { FormItem } from '../molecules/FormItem';
import { AuthLayout } from '../templates/AuthLayout';
import { Input } from '../atoms/Input';
import { Button } from '../atoms/Button';
import { useAlert } from '../../contexts/AlertContext';
import { validatePartnerPassword } from '../../api/user';


interface PartnerRouteProps {
  children: ReactNode;
}

export const PartnerRoute: FC<PartnerRouteProps> = ({ children }) => {
  const alert = useAlert();
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [isValidatePasswordLoading, setIsValidatePasswordLoading] = useState(false);

  const onFormSubmit = async ({ password }: { password: string }) => {
    setIsValidatePasswordLoading(true);

    try {
      const result = await validatePartnerPassword(password);

      if (!result.isValid) {
        alert?.error('The password is incorrect!');
      }

      setIsPasswordCorrect(result.isValid);
    } catch (err) {
      console.log('err', err);
      alert?.displayErrorAlert(err);
    } finally {
      setIsValidatePasswordLoading(false);
    }
  };

  if (!isPasswordCorrect) {
    return (
      <AuthLayout>
        <Form
          requiredMark={false}
          layout="vertical"
          onFinish={onFormSubmit}
          disabled={isValidatePasswordLoading}
        >
          <FormItem label="Password" name="password">
            <Input placeholder="Enter password" type="password" />
          </FormItem>

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 'auto' }}
            loading={isValidatePasswordLoading}
          >
            Submit
          </Button>
        </Form>
      </AuthLayout>
    );
  }

  return <>{children}</>;
};
