import styled, { css } from 'styled-components';
import { Input } from 'antd';


const commonInputStyles = css`
  height: 44px;
  padding: 12px 16px;
  font-weight: 400;
  box-shadow: none;

  & svg {
    fill: ${({ theme }) => theme.color.greyscale.grey60};
  }

  &:hover {
    cursor: text;
  }

  &&:focus-within, &&:focus {
    border-color: ${({ theme }) => theme.color.primary.blue60};
    caret-color: ${({ theme }) => theme.color.primary.blue80};
    box-shadow: none;
  }

  &&:focus-within svg {
    fill: ${({ theme }) => theme.color.primary.blue80};
  }
`;

export const StyledInput = styled(Input)`
  ${commonInputStyles};
`;

export const StyledPasswordInput = styled(Input.Password)`
  ${commonInputStyles};
`;
