/* eslint-disable max-len */
import styled from 'styled-components';
import { Layout } from 'antd';


export const StyledAuthLayoutWrapper = styled(Layout)`
  min-height: 100vh;
  background: ${({ theme }) => theme.currentModeConfig.contentBackground};
`;

export const StyleAuthLayoutHeader = styled(Layout.Header)`
  && {
    background: transparent;
    padding-top: 32px;
    height: auto;
    line-height: 1;
    margin-inline: auto;
  }
`;

export const StyledAuthLayoutContent = styled(({ centerVertically, ...rest }) => <Layout.Content {...rest} />)<{ centerVertically?: boolean }>`
  display: flex;
  justify-content: ${({ centerVertically }) => centerVertically && 'center'};
  align-items: center;
  flex-direction: column;
`;
