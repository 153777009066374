import styled, { css } from 'styled-components';
import { Form } from 'antd';
import ReactMarkdown from 'react-markdown';
import InfoCircleRedIcon from '../../../assets/icons/info-circle-red.svg';
import { FormItemProps } from './FormItem.types';


export const StyledFormItem = styled(({
  subTitle,
  largeLabel,
  hideInputControl,
  errorMessageTextAlign,
  errorMessageJustify,
  ...rest
}) => <Form.Item {...rest} />)<FormItemProps>`
  margin-bottom: 20px;

  & .ant-form-item-label > label {
    height: 100%;
    width: 100%;
    font-size: ${({ largeLabel }) => (largeLabel ? '16px' : '')};
    font-weight: ${({ largeLabel }) => (largeLabel ? 500 : '')};
    display: block;
  }
  
  & .ant-form-item-control {
    input:not(.ant-input-disabled), input:not(:checked){
      background: transparent;
      border-color: ${({ theme }) => theme.currentModeConfig.inputBorderColor};
    }

    input:not(:checked){
      & + .ant-checkbox-inner {
        background: transparent;
      }
    }
  }

  & .ant-form-item-explain-error {
    display: flex;
    justify-content: ${({ errorMessageJustify }) => errorMessageJustify || 'end'};
    align-items: center;
    gap: 4px;
    color: ${({ theme }) => theme.color.states.red};
    text-align: ${({ errorMessageTextAlign }) => errorMessageTextAlign || 'right'};
    margin-top: 6px;
    margin-bottom: 4px;

    ::before {
      content: url(${InfoCircleRedIcon});
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }
  
  &&& .ant-form-item-tooltip {
    position: absolute;
    top: 5px;
    right: 0;
    margin-left: auto;
    color: ${({ theme }) => theme.color.primary.blue80};
  }

  ${({ subTitle }) => subTitle && css`
    && .ant-form-item-label::after {
      display: block;
      font-size: 12px;
      font-weight: 500;
    }
  `}

  ${({ hideInputControl }) => hideInputControl && css`
    && .ant-form-item-control-input {
      display: none;
    }
  `}
`;

export const StyledReactMarkdownTitle = styled(ReactMarkdown)`
  font-size: 12px;
  font-weight: 500;
  max-width: 90%;
  margin-top: 4px;
  
  p {
    margin: 0;
  }
`;
