import type { FC, ReactNode } from 'react';
import { Logo } from '../../atoms/Logo';
import { StyleAuthLayoutHeader, StyledAuthLayoutContent, StyledAuthLayoutWrapper } from './AuthLayout.styles';


interface AuthLayoutProps {
  children: ReactNode;
  centerVertically?: boolean;
}

export const AuthLayout: FC<AuthLayoutProps> = ({ centerVertically, children }) => (
  <StyledAuthLayoutWrapper>
    <StyleAuthLayoutHeader>
      <Logo />
    </StyleAuthLayoutHeader>

    <StyledAuthLayoutContent centerVertically={centerVertically}>
      {children}
    </StyledAuthLayoutContent>
  </StyledAuthLayoutWrapper>
);
