import type { FC } from 'react';
import { useMemo } from 'react';
import { StyledFormItem, StyledReactMarkdownTitle } from './FormItem.styles';
import { FormItemProps } from './FormItem.types';


export const FormItem: FC<FormItemProps> = ({ label, subTitle, ...rest }) => {
  const renderLabelWithSubTitle = useMemo(() => {
    if (!label) return;

    return (
      <>
        {label && <div>{label}</div>}
        {subTitle && <StyledReactMarkdownTitle>{subTitle}</StyledReactMarkdownTitle>}
      </>
    );
  }, [label, subTitle]);

  return (
    <StyledFormItem {...rest} label={renderLabelWithSubTitle} />
  );
};
